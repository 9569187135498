import React from 'react'

function Invoice() {
  return (
    <div className='w-6/12 mx-auto mt-20 py-10 border-2 p-5 mb-10'>
    <div>
        <div className='flex justify-between'>
            <div className=' flex flex-col justify-start '>
                <div className='text-gray-500 font-bold text-lg'>From</div>
                <div className='font-bold text-lg'>Sanju Kanwar</div>
            </div>
        
            <div className='font-bold text-xl'>INVOICE</div>
        </div>
        <div className='mt-10 flex justify-between'>
            <div>
                <div className='uppercase text-gray-500 font-bold'>Invoice To :</div>
                <div className='font-bold'>BENTODENT</div>
                <div className='font-bold'>GSTIN : </div>
            </div>
            <div className='font-bold'>
                <div>Date : 27-06-2024</div>
                <div>Invoice No : IN02</div>
            </div>
            
        </div>
        <div className='flex justify-between mt-10 border-2 bg-rose-500 p-2 text-white text-lg font-bold rounded-md'>
                <div className='flex gap-x-4'>
                    <div>No</div>
                    <div className='ml-2'>Description</div>
                </div>
                <div className='mr-4'>
                    Price
                </div>
        </div>
        <div className='flex justify-between mt-1 text-lg'>
                <div className='flex gap-x-4'>
                    <div className='ml-2'>1.</div>
                    <div className='ml-6'>Collaboration Service</div>
                </div>
                <div>
                    7500 INR
                </div>
        </div>
        </div>
        <div className='flex justify-end items-end'>
            <div className=' mt-4 bg-rose-500 p-2 uppercase text-white text-lg font-bold rounded-md'>Total : 7500 INR</div>
        </div>
        <div className='flex justify-end mt-4 text-lg'>Thank you for business with me.</div>
        <div className='font-bold text-lg mt-4'>Bank Details :</div>
        <div className=' text-lg mt-4'>
            <div>Bank Name : <span className='font-bold text-blue-600'>HDFC BANK</span></div>
            <div>Name : <span className='font-bold text-blue-600'>Sanju Kanwar</span></div>
            <div>Account Number : <span className='font-bold text-blue-600'>28381140001007</span></div>
            <div>Account Type : <span className='font-bold text-blue-600'>Saving</span></div>
            <div>IFSC Code : <span className='font-bold text-blue-600'>HDFC0002838</span></div>
            <br/>
            <div>GPay : <span className='font-bold text-blue-600'>+91 8890223336</span></div>
        </div>
        <div className='flex justify-end mt-4 font-bold text-lg'>
            <div>
                <div>Regards,</div>
                <div>Sanju Kanwar</div>
            </div>
        </div>
    </div>
  )
}

export default Invoice