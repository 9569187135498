import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../config/firebase';

const AddSocialCount = () => {
    const [instaCount, setInstaCount] = useState('');
    const [youtubeCount, setYouTubeCount] = useState('');
    const [instaReelCount, setInstaReelCount] = useState('');
    const [posts, setPosts] = useState([]);
    const [docId, setDocId] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    const codeCollectionRef = collection(db, "Social Media Count");

    const handleSubmit = async() => {
        try{
            await addDoc(codeCollectionRef, {
                instacount : instaCount,
                youtubecount : youtubeCount,
                instareelcount : instaReelCount
              });

              alert('Count Added Successfully !!!');
              getCode();
              clearData();
        }
        catch(er)
        {
            console.log(er);
        }
    }

    const handleUpdate = async() => {
        try{
            const codeDoc = doc(db, "Social Media Count", docId);
            await updateDoc(codeDoc, 
            {
                instacount : instaCount,
                youtubecount : youtubeCount,
                instareelcount : instaReelCount
            });

            alert('Count updated successfully !!!');
            getCode();
            clearData();
        }
        catch(er){
            console.log(er);
        }
    }

    const handleEdit = (p) => {
        setInstaCount(p.instacount);
        setYouTubeCount(p.youtubecount);
        setInstaReelCount(p.instareelcount);
        setDocId(p.id);
        setIsSubmit(true);

        window.scroll(0,0);
    }

    const handleDelete = async(id) => {
        var res = window.confirm("Delete the item?");
        if (res) {
        const foodDoc = doc(db, "Social Media Count", id);
        await deleteDoc(foodDoc);

        getCode();
        clearData();
    } else {
      // Do nothing!
      console.log('Not deleted');
    }
    }

    const getCode = async() => {
        try{
            const data = await getDocs(codeCollectionRef);
            const filteredData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));
        
            setPosts(filteredData);
        }
        catch(er){
            console.log(er);
        }
    }

    const clearData = () => {
        setInstaCount('');
        setYouTubeCount('');
        setInstaReelCount('');
        setDocId('');
        setIsSubmit(false);
    }

    useEffect(() => {
        getCode();
    },[]);

  return (
    <div className='w-full md:w-5/12 mx-auto flex flex-col justify-center items-center mt-8 px-2 md:px-5'>
        <div className='flex-col justify-between w-full mx-auto py-4'>
            <div className='font-semibold text-xl text-center w-full'>Add Social Media Count</div>

            <div className='w-full mx-auto mt-10'>
                <div className='mt-4 text-lg py-1'>Insta Count <sup className='text-red-500 text-lg'>*</sup></div>
                    <input
                        className='border-2 rounded-md py-3 w-full px-2 text-lg'
                        required
                        name='name'
                        placeholder='Enter insta count'
                        value={instaCount}
                        onChange={(e) => setInstaCount(e.target.value)}  />
            </div>
            <div className='w-full mx-auto mt-10'>
                <div className='mt-4 text-lg py-1'>YouTube Count <sup className='text-red-500 text-lg'>*</sup></div>
                    <input
                        className='border-2 rounded-md py-3 w-full px-2 text-lg'
                        required
                        name='name'
                        placeholder='Enter youtube count'
                        value={youtubeCount}
                        onChange={(e) => setYouTubeCount(e.target.value)}  />
            </div>
            <div className='w-full mx-auto mt-10'>
                <div className='mt-4 text-lg py-1'>Insta Reel Count <sup className='text-red-500 text-lg'>*</sup></div>
                    <input
                        className='border-2 rounded-md py-3 w-full px-2 text-lg'
                        required
                        name='name'
                        placeholder='Enter insta reel count'
                        value={instaReelCount}
                        onChange={(e) => setInstaReelCount(e.target.value)}  />
            </div>
            <div className=' py-8 flex justify-end'>
            {
                isSubmit ? ( <button onClick={handleUpdate} className='bg-orange-400 text-white px-4 py-2 font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer'>Update</button>)
                         : ( <button onClick={handleSubmit} className='bg-[#146eb4] text-white px-4 py-2 font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer'>Submit</button>)
            }
               
            </div>
            </div>

            <div className=' w-full mx-auto py-2'>
            
            
            <div className="overflow-hidden mt-2 py-2">
   <table className="w-full md:w-10/12 mx-auto overflow-y-scroll text-left text-sm font-light">
            
            <thead className="font-medium ">
                    <tr className='bg-gray-100 rounded-md font-semibold text-lg'>
                            <th scope="col" className="px-1 py-2 border-r-2 text-center">#</th>
                            <th scope="col" className="px-1 py-2 border-r-2 text-center">Instagram</th>
                            <th scope="col" className="px-1 py-2 border-r-2 text-center">Youtube</th>
                            <th scope="col" className="px-1 py-2 border-r-2 text-center">Reel</th>
                            <th scope="col" className="px-1 py-2  text-center border-r-2" >Edit</th>
                            <th scope="col" className="px-1 py-2 border-r-2  text-center text-wrap" >Delete</th>
                        </tr>
            </thead>

                <tbody>
            {
                
                posts.map((p, index) => (
                    
                    <tr className="border-2 border-black ">
                        <td className="whitespace-wrap text-[12px] font-medium px-1 py-2 border-r-2  align-baseline text-center text-wrap">{index+1}</td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center text-wrap">{p.instacount}</td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center text-wrap">{p.youtubecount}</td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center text-wrap">{p.instareelcount}</td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center">
                        <button onClick={() => handleEdit(p)} className='bg-blue-600 px-4 py-1 rounded-lg text-white'>Edit</button></td>
                        <td className="whitespace-wrap text-md font-medium px-1 py-2 border-r-2 align-baseline text-center border-black">
                        <button onClick={() => handleDelete(p.id)} className='bg-red-600 px-4 py-1 rounded-lg text-white'>Delete</button>
                        </td>
                        
                    </tr>)
        )     
       } 
       </tbody> 
        
       </table>
   </div> 
     
    </div>
       
    </div>
  )
}

export default AddSocialCount