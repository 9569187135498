import React, { useEffect, useState } from 'react'
import InstaVideoCard from './InstaVideoCard'
import { CATEGORY_12_PLUS_MONTHS, CATEGORY_6_TO_12_MONTHS, CATEGORY_ALL } from '../utils/contants';
import ShimmerUICard from './ShimmerUICard';

const InstaVideos = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [instaVideos, setInstaVideos] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const fetchVideos = async() => {
    try
    {
        setLoading(true);

        const instaVideos = JSON.parse(localStorage.getItem('recipe-videos'));

        setInstaVideos(instaVideos);  
        setPosts(instaVideos);
        setFilterData(instaVideos);

        setLoading(false);
    }
    catch(er)
    {
       console.log(er);
    }
  }

  const handleClick = (category) => {
    
    setLoading(true);
    setSearchText('');

    let posts = [];
    if(category === 'All')
    {
       posts = instaVideos;
    }
    else{
      posts = instaVideos.filter(x => x.category === category);
    }
      
    setFilterData(posts);

    setLoading(false);
  }
  
  const handleSearch = (text) => {
    setSearchText(text);
    const data = posts.filter(x => x.title.toLowerCase().includes(text.toLowerCase()));
    setFilterData(data);
  }
  useEffect(() => {
    fetchVideos();
    window.scroll(0,0);
  },[]);

  return (
    <div className='w-full md:w-10/12 mx-auto text-lg p-8 mt-4'>
          <div className='text-3xl uppercase text-blue-700 font-mono font-bold mb-8 text-center'>Recipe Videos</div>
          <div className='w-full mx-auto mt-2'>
                <div className='mt-4 text-lg py-1 font-medium '>Search Recipe :</div>
                <div className='flex flex-col md:flex-row gap-x-4'>
                    <input
                        className='border-2 rounded-md py-3 w-full md:w-4/12 px-2 text-lg'
                        required
                        name='name'
                        placeholder='Enter recipe name here...'
                        value={searchText}
                        onChange={(e) => handleSearch(e.target.value)}  />
                  {
                    searchText && <div className='mt-3 text-gray-500'>Found {filterData.length} recipes for "{searchText}"</div>
                  }
                    
                    </div>
              </div>
          <h3 className="mt-4 text-lg py-1 font-medium">Get Recipe By Age : </h3>
          <div >
<ul className="flex flex-wrap md:flex-nowrap w-full gap-6">
    <li className='w-full md:w-4/12' onClick={() => handleClick(CATEGORY_ALL)}>
        <input type="radio" id="hosting-small1" name="hosting" value="hosting-small1" className="hidden peer" required />
        <label htmlFor="hosting-small1" className="inline-flex items-center justify-between w-full p-2 md:p-5 text-gray-500 border border-black rounded-lg cursor-pointer  peer-checked:bg-green-500  peer-checked:text-white hover:text-gray-600 hover:bg-green-500 bg-amber-200">                           
            <div className="block">
                <div className="w-full text-md md:text-lg font-semibold">All Recipes</div>
            </div>
            <svg className="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </label>
    </li> 
    <li className='w-full md:w-4/12' onClick={() => handleClick(CATEGORY_6_TO_12_MONTHS)}>
        <input type="radio" id="hosting-small" name="hosting" value="hosting-small" className="hidden peer" required />
        <label htmlFor="hosting-small" className="inline-flex items-center justify-between w-full p-2 md:p-5 text-gray-500 border border-black rounded-lg cursor-pointer  peer-checked:bg-green-500  peer-checked:text-white hover:text-gray-60 hover:bg-green-500 bg-amber-200">                           
            <div className="block">
                <div className="w-full text-md md:text-lg font-semibold ">6 to 12 Months</div>
            </div>
            <svg className="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </label>
    </li>
    <li className='w-full md:w-4/12' onClick={() => handleClick(CATEGORY_12_PLUS_MONTHS)}>
        <input type="radio" id="hosting-big" name="hosting" value="hosting-big" className="hidden peer"/>
        <label htmlFor="hosting-big" className="inline-flex items-center justify-between w-full p-2 md:p-5 text-gray-500 border border-black rounded-lg cursor-pointer  peer-checked:bg-green-500  peer-checked:text-white hover:text-gray-600 hover:bg-green-500 bg-amber-200">
            <div className="block">
                <div className="w-full text-md md:text-lg font-semibold ">12+ Months</div>
            </div>
            <svg className="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </label>
    </li>
</ul>

      </div>
            {
              loading ? (<ShimmerUICard/>) : (<div className='flex flex-wrap w-full md:gap-x-6 gap-y-10 justify-between mt-5 py-5 items-center'>
            {
              filterData && filterData.length > 0 && filterData.map((post) => (
                <InstaVideoCard key={post.title} url={post.InstaUrl} imageUrl={post.imageUrl} title={post.title}/>
              ))
            }
            </div>)
            }
  </div>
  )
}

export default InstaVideos